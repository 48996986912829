import React, { useState, useEffect } from "react"
import ReactDOM from 'react-dom/client'
import './index.css'
import Home from './pages/Home/index.js'
import Mint from './pages/Mint/index.js'
import './assets/css/style.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {LOTTERY_ADDRESS, LOTTERY_ABI, NFT_ADDRESS, NFT_ABI, RARITIES, RPC_URL} from './config.js'
import { createWeb3Modal, defaultConfig, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { createClient } from '@vercel/kv'

const { ethers } = require("ethers")

// Web3modal
// 1. Get projectId
const projectId = '60013a9016eb7ae469e5363fd9f09cc7'

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

const mumbai = {
    chainId: 80001,
    name: 'Mumbai',
    currency: 'MATIC',
    explorerUrl: 'https://mumbai.polygonscan.com',
    rpcUrl: 'https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78'
}

const base = {
    chainId: 8453,
    name: 'Base Mainnet',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://mainnet.base.org'
}

// 3. Create modal
const metadata = {
  name: 'Degen or Bust',
  description: 'Degen or Bust',
  url: 'https://degenorbust.xyz',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [base],
  projectId,
  enableAnalytics: true,
  themeVariables: {
    '--w3m-accent': '#5670FF',
    // '--w3m-z-index': -100000
  }
})

function App() {
  
  const [visiblePastWinners, setVisiblePastWinners] = useState(false)
  const [visiblePlay, setVisiblePlay] = useState(false)
  const [visibleFaq, setVisibleFaq] = useState(false)
  const [visibleHowToPlay, setVisibleHowToPlay] = useState(false)
  const [visibleResponsibility, setVisibleResponsibility] = useState(false)
  const [visibleTOS, setVisibleTOS] = useState(false)
  const [visibleTx, setVisibleTx] = useState(false)
  const [visibleSelectNft, setVisibleSelectNft] = useState(false)
  const [visibleUseNft, setVisibleUseNft] = useState(false)
  const [visibleResults, setVisibleResults] = useState(false)
  
  // wallet data
  const [txHash, setTxHash] = useState(null)
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  
  // nft data
  const [tokensOfOwner, setTokensOfOwner] = useState([])
  const [unusedTokensOfOwner, setUnusedTokensOfOwner] = useState([])
  const [ownedLevel1, setOwnedLevel1] = useState([])
  const [ownedLevel2, setOwnedLevel2] = useState([])
  const [ownedLevel3, setOwnedLevel3] = useState([])
  const [ownedLevel4, setOwnedLevel4] = useState([])
  const [ownedLevel5, setOwnedLevel5] = useState([])
  const [selected, setSelected] = useState([])
  const [selectedNft, setSelectedNft] = useState('')
  const [selectedNftLevel, setSelectedNftLevel] = useState('')
  
  // lottery contract data
  const [startBool, setStartBool] = useState(true)
  const [entryFee, setEntryFee] = useState(0)
  const [prizePool, setPrizePool] = useState(0)
  const [allDraws, setAllDraws] = useState([])
  const [recentEntries, setRecentEntries] = useState([])
  const [recentWinners, setRecentWinners] = useState([])

  // nft contract data
  const [nftStarted, setNftStarted] = useState(true)
  const [nftPrice, setNftPrice] = useState('')
  const [nftSupply, setNftSupply] = useState(0)
  
  // other data
  const [etherPrice, setEtherPrice] = useState(0)
  
  // ethers wallet
  const [provider, setProvider] = useState({})
  const [signer, setSigner] = useState({})
  
  const sleep = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms))

  const kv = createClient({
    url: 'https://fit-squirrel-54902.upstash.io',
    token: 'AtZ2AAIgcDHkTttYflfe8ch3AzPL7BfCjJF71sMBbqqwT1Q_6ISvWQ'
  })

  useEffect(() => {
    readGecko()
    readLotteryDb()
    readNftDb()
  }, [])
  
  useEffect(() => {
    signIn()
  }, [isConnected])
  
  async function signIn() {
    if (!isConnected) {
      return
    }
    
    const provider =  new ethers.providers.Web3Provider(walletProvider)
    const signer = await provider.getSigner()
    setProvider(provider)
    setSigner(signer)
    
    console.log(`Connected to chain ${chainId} with wallet ${address}`)
    
    await getOwned(address)
    
  }

  // Time calculation

  function padTo2Digits(num) {
      return num.toString().padStart(2, '0')
  }

  function toSecondsHoursAndMinutes(totalSeconds) {
      const seconds = Math.floor(totalSeconds % 60)
      const minutes = Math.floor(totalSeconds / 60  % 60)
      const hours = Math.floor(totalSeconds / 3600)
      if (minutes == 0 && hours == 0) {
          return `${padTo2Digits(seconds)}s`
      } else if (hours == 0) {
          return `${minutes}m ${padTo2Digits(seconds)}s`
      }
        else {
          return `${hours}h ${minutes}m`
      }
  }

  function appendTimeDelta(object) {
      const timeDelta = Date.now()/1000 - object['timestamp']
      const time = toSecondsHoursAndMinutes(timeDelta)
      object['time'] = time
      return object
  }

  // API calls
  
  async function readGecko() {
      fetch('https://api.coingecko.com/api/v3/coins/ethereum', {
          method: 'GET',
          localization: 'false',
          tickers: 'false',
          market_data: 'true',
          community_data: 'false',
          developer_data: 'false',
          sparkline: 'false'
      }).then(response => response.json())
      .then(data => setEtherPrice(data.market_data.current_price.usd))
  }

  // DB calls
  
  async function readLotteryDb() {
      
      const startBool = await kv.get('startBool')
      setStartBool(startBool)
  
      const entryFee = await kv.get('entryFee')
      setEntryFee(entryFee.toString())
  
      const prizePool = await kv.get('prizePool')
      setPrizePool(prizePool)

      const allDrawsRaw = await kv.lrange('draws', 0, -1)
      const allDraws = allDrawsRaw.map(draw => appendTimeDelta(draw))
      setAllDraws(allDraws)
  
      const allEntriesRaw = await kv.lrange('entries', 0, -1)
      const allEntries = allEntriesRaw.map(entry => appendTimeDelta(entry))
      setRecentEntries(allEntries.slice(0,100))
  
      const allWinnersRaw = await kv.lrange('winners', 0, -1)
      const allWinners = allWinnersRaw.map(winner => appendTimeDelta(winner))
      setRecentWinners(allWinners)
  
      console.log(`Read lottery data from kv database`)
  }
  
  async function readNftDb() {
      const nftStarted = await kv.get('nftStarted')
      setNftStarted(nftStarted)
  
      const nftPrice = await kv.get('nftPrice')
      setNftPrice(nftPrice.toString())

      const nftSupply = await kv.get('nftSupply')
      setNftSupply(nftSupply)

      console.log(`Read NFT data from kv database`)
  }

  // Wallet RPC calls

  async function getOwned(address) {
      const provider =  new ethers.providers.Web3Provider(walletProvider)
      const nftContract = new ethers.Contract(NFT_ADDRESS, NFT_ABI, provider)
      const lotteryContract = new ethers.Contract(LOTTERY_ADDRESS, LOTTERY_ABI, provider)
      
      const ownedBig = await nftContract.tokensOfOwner(address)
      const owned = [] 
      ownedBig.forEach(element => owned.push(parseFloat(element)))
      setTokensOfOwner(owned)

      const currentDraw = await lotteryContract.currentDrawNumber()
      const unused = []
      
      for (let i=0; i<owned.length; i++) {
        if (!await lotteryContract.usedNFTMap(currentDraw, owned[i])) {
          unused.push(owned[i])
        }
        await sleep(100)
      }

      setUnusedTokensOfOwner(unused)

      if (owned.length > 0) {
          console.log(`Wallet owns ${owned.length} NFTs of contract ${NFT_ADDRESS}, ${unused.length} unused`)
      }

      let level1 = []
      let level2 = []
      let level3 = []
      let level4 = []
      let level5 = []

      for (const tokenId of owned) {

          const rarity = RARITIES[tokenId]

          switch (rarity) {
              case 1:
                  level1.push(tokenId)
                  break
              case 2:
                  level2.push(tokenId)
                  break
              case 3:
                  level3.push(tokenId)
                  break
              case 4:
                  level4.push(tokenId)
                  break
              case 5:
                  level5.push(tokenId)
                  break
              default:
                  console.log('Rarity case error')
          }
      }
      
      setOwnedLevel1(level1)
      setOwnedLevel2(level2)
      setOwnedLevel3(level3)
      setOwnedLevel4(level4)
      setOwnedLevel5(level5)
  }

  async function updateLotteryData() {
      const contract = new ethers.Contract(LOTTERY_ADDRESS, LOTTERY_ABI, provider)

      const prizePoolBig = await provider.getBalance(LOTTERY_ADDRESS)
      const prizePool = ethers.utils.formatEther(prizePoolBig)
      setPrizePool(prizePool)

      const currentBlock = await provider.getBlockNumber()
      const ticketSales = await contract.queryFilter('ticketSale', currentBlock-30, currentBlock)
      let allEntries = recentEntries

      for (const sale of ticketSales) {
          const block = sale['blockNumber']
          const blockData = await provider.getBlock(block)
          const timeDelta = Date.now()/1000 - blockData['timestamp']
          const entry = {
              player: sale['args']['player'],
              tickets: sale['args']['tickets'].toString(),
              time: toSecondsHoursAndMinutes(timeDelta),
              hash: sale['transactionHash']
          }
          allEntries.unshift(entry)
      }

      setRecentEntries(allEntries.slice(0,100))

      console.log(`Updated lottery contract data using wallet provider`)
  }

  async function enterDraw(balls) {
      let fee = ''
      let sortedBalls = []
      setTxHash(null)
  
      if (balls.length == 5) {
          sortedBalls = balls.sort(function(a, b){return a-b})
          fee = ethers.utils.parseUnits(entryFee, 'ether')
      } else {
          sortedBalls = balls
          fee = ethers.utils.parseUnits(entryFee, 'ether').mul(balls.length/5)
      }

      const lotteryContractSigner = new ethers.Contract(LOTTERY_ADDRESS, LOTTERY_ABI, signer)
    
      console.log(`Entering with ${sortedBalls}`)
    
          setVisiblePlay(false)
          showTx()
      const tx = await lotteryContractSigner.enter(sortedBalls, {value: fee})
      const receipt = await tx.wait();
      console.log(`Tx success, hash ${receipt.transactionHash}`)
          setTxHash(receipt.transactionHash)
    
      await updateLotteryData()
  }
  
  async function enterDrawWithNft(balls, tokenId) {
      setTxHash(null)

      const fee = ethers.utils.parseUnits(entryFee, 'ether')
  
      const lotteryContractSigner = new ethers.Contract(LOTTERY_ADDRESS, LOTTERY_ABI, signer)
    
      console.log(`Entering with ${balls}`)
            setVisiblePlay(false)
            showTx()
    
      const tx = await lotteryContractSigner.enterWithNFT(balls, tokenId, {value: fee})
      const receipt = await tx.wait();
      console.log(`Tx success, hash ${receipt.transactionHash}`)
            setTxHash(receipt.transactionHash)
  
      await updateLotteryData()
      await getOwned()
  }
  
  const showTx = () => {
      setVisibleTx(true)
  }

  async function mintNft(number) {
      setTxHash(null)

      const nftContractSigner = new ethers.Contract(NFT_ADDRESS, NFT_ABI, signer)
      const fee = ethers.utils.parseUnits(nftPrice, 'ether').mul(number)
    
      console.log(`Minting ${number} Degen nft`)
          showTx()
      const tx = await nftContractSigner.mintDegen(number, {value: fee})
      const receipt = await tx.wait();
      console.log(`Tx success, hash ${receipt.transactionHash}`)
          setTxHash(receipt.transactionHash)
  }

  return (
    <Router>
    <Routes>
        <Route path='/' element={<Home visiblePastWinners={visiblePastWinners} setVisiblePastWinners={setVisiblePastWinners} visiblePlay={visiblePlay} setVisiblePlay={setVisiblePlay} visibleFaq={visibleFaq} setVisibleFaq={setVisibleFaq} visibleHowToPlay={visibleHowToPlay} setVisibleHowToPlay={setVisibleHowToPlay} visibleResponsibility={visibleResponsibility} setVisibleResponsibility={setVisibleResponsibility} visibleTOS={visibleTOS} setVisibleTOS={setVisibleTOS} visibleTx={visibleTx} setVisibleTx={setVisibleTx} visibleSelectNft={visibleSelectNft} setVisibleSelectNft={setVisibleSelectNft} visibleUseNft={visibleUseNft} setVisibleUseNft={setVisibleUseNft} walletConnected={isConnected} walletAddress={address} signIn={signIn} txHash={txHash} setTxHash={setTxHash} tokensOfOwner={tokensOfOwner} setTokensOfOwner={setTokensOfOwner} ownedLevel1={ownedLevel1} ownedLevel2={ownedLevel2} ownedLevel3={ownedLevel3} ownedLevel4={ownedLevel4} ownedLevel5={ownedLevel5} selected={selected} setSelected={setSelected} selectedNft={selectedNft} setSelectedNft={setSelectedNft} selectedNftLevel={selectedNftLevel} setSelectedNftLevel={setSelectedNftLevel} startBool={startBool} setStartBool={setStartBool} entryFee={entryFee} setEntryFee={setEntryFee} prizePool={prizePool} setPrizePool={setPrizePool} recentEntries={recentEntries} setRecentEntries={setRecentEntries} recentWinners={recentWinners} setRecentWinners={setRecentWinners} etherPrice={etherPrice} enterDraw={enterDraw} enterDrawWithNft={enterDrawWithNft} nftSupply={nftSupply} RARITIES={RARITIES} unusedTokensOfOwner={unusedTokensOfOwner} visibleResults={visibleResults} setVisibleResults={setVisibleResults} allDraws={allDraws} />} />        
        
        <Route path='/mint' element={<Mint visiblePastWinners={visiblePastWinners} setVisiblePastWinners={setVisiblePastWinners} visiblePlay={visiblePlay} setVisiblePlay={setVisiblePlay} visibleFaq={visibleFaq} setVisibleFaq={setVisibleFaq} visibleHowToPlay={visibleHowToPlay} setVisibleHowToPlay={setVisibleHowToPlay} visibleResponsibility={visibleResponsibility} setVisibleResponsibility={setVisibleResponsibility} visibleTOS={visibleTOS} setVisibleTOS={setVisibleTOS} visibleTx={visibleTx} setVisibleTx={setVisibleTx} visibleSelectNft={visibleSelectNft} setVisibleSelectNft={setVisibleSelectNft} visibleUseNft={visibleUseNft} setVisibleUseNft={setVisibleUseNft} walletConnected={isConnected} walletAddress={address} signIn={signIn} txHash={txHash} setTxHash={setTxHash} tokensOfOwner={tokensOfOwner} setTokensOfOwner={setTokensOfOwner} ownedLevel1={ownedLevel1} ownedLevel2={ownedLevel2} ownedLevel3={ownedLevel3} ownedLevel4={ownedLevel4} ownedLevel5={ownedLevel5} selected={selected} setSelected={setSelected} selectedNft={selectedNft} setSelectedNft={setSelectedNft} selectedNftLevel={selectedNftLevel} setSelectedNftLevel={setSelectedNftLevel} startBool={startBool} setStartBool={setStartBool} entryFee={entryFee} setEntryFee={setEntryFee} prizePool={prizePool} setPrizePool={setPrizePool} recentEntries={recentEntries} setRecentEntries={setRecentEntries} recentWinners={recentWinners} setRecentWinners={setRecentWinners} etherPrice={etherPrice} enterDraw={enterDraw} enterDrawWithNft={enterDrawWithNft} nftStarted={nftStarted} nftPrice={nftPrice} nftSupply={nftSupply} mintNft={mintNft} visibleResults={visibleResults} setVisibleResults={setVisibleResults} allDraws={allDraws} />} />
    </Routes>
    </Router>
  )

}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

export default App;